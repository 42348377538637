import {FC} from 'react';
import styled from 'styled-components';
import {Checkbox} from 'antd';
import {CampaignPublicType} from "../../../../../project-types/campaign/types";
import {CampaignInviteAcceptType} from "../../../../../project-types/campaign-join/types";
import {SchoolPublicTypeLite} from "../../../../../project-types/schools/types";

interface SchoolContributionProps {
    urlka: string | undefined;
}

export default function SchoolContribution({urlka}: SchoolContributionProps) {
    const TextField = styled.iframe.attrs({src: '/privacy.html'})`
      width: 720px;
      height: 380px;
      border: 0px solid #909da8;
      box-sizing: border-box;
      border-radius: 4px;
      overflow: auto;
      position: relative;
    `;
    return (
        <Container>
            <NumberInSquare>6</NumberInSquare>
            <Content>
                <Title>School Contribution Agreement</Title>

                <Subtitle>Please read and accept below</Subtitle>
                {urlka ? (
                    <iframe src={urlka} style={{
                        width: '720px',
                        height: '380px',
                        border: '0px solid #909da8',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        overflow: 'auto',
                        position: 'relative'}}></iframe>
                ): (
                    <TextField/>
                )}


                <Checkbox onChange={() => {
                }}>
                    <CheckboxText>
                        I have read and agree to the Contributor Agreement*
                    </CheckboxText>
                </Checkbox>
            </Content>

        </Container>
    );
};


const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 28px;
  background-color: rgba(245, 248, 251, 0.7);
  margin-top: 30px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #e8f4ff;
  color: #2682de;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #909da8;
`;

const CheckboxText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #102f54;
`;

