import {
  CampaignCreateType,
  CampaignPublicType,
  CollectionForm,
} from '../../project-types/campaign/types';
import { ApiCreateSchool } from '../../project-types/schools/api-types';
import {
  SchoolRegisterType,
  SchoolUpdateType,
} from '../../project-types/schools/types';
import { UserRegisterType } from '../../project-types/users/types';

export interface SignUpSchoolFields
  extends UserRegisterType,
    SchoolRegisterType {}

export interface SignUpSchoolForm extends UserRegisterType, SchoolRegisterType {
  agree: boolean;
  confirmPassword: string;
}

export type SchoolFormFieldType = ApiCreateSchool['data']['schoolProfile'];
export type SchoolFormUpdateType = Omit<
  Required<SchoolUpdateType>,
  'email' | 'password' | 'URN' | 'adress2' | 'adress3' | 'logo'
>;
export type SchoolLoginFields = UserRegisterType;

export type CampaignFormFields = Omit<CampaignCreateType, 'isFreezed'>;
export type CollectionFormFileds = Omit<CollectionForm, 'isFreezed'>;

export type DonorTableActions =
  | 'Resume collections'
  | 'Remove donor'
  | 'Freeze collections';

export type DonorStatuses = 'Frozen' | 'Active';

export interface DonorTableRow {
  key: string;
  fullName: string;
  email: string;
  amount: string;
  numberOfDonations: string;
  total: string;
  giftAid: string;
  giftAidAmount: string;
  studentReferences: string;
  note: string;
}

export interface DonationsTableRow {
  key: string;
  postedDate: string;
  fullName: string;
  status: string;
  amount: string;
  giftAidAmount: string;
  studentReferences: string;
}

export interface CampaignsTableRow {
  key: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  registeredDonors: number;
  totalDonationsCollected: string;
  giftAidClaimed: string;
  totalRaised: string;
}

export interface ManageTableModel {
  key: string;
  firstCollectionDate: string;
  fullName: string;
  students: Array<string>;
  timePeriod: string;
  donationAmount: string;
  collectionType: string;
  giftAidRegistered: string;
  initialDonationAmount: string;
  collectionsAmounts: number;
  donationsNumber: number
  status: string;
  note: string;
}

export interface DeviceTradeinTableRow {
  key: string;
  dateSubmitted: string;
  type: string;
  nameOrReference: string;
  quantityRegistred: number;
  expectedValue: string;
}

export interface RegistredDevicesTradeinTableRow {
  key: string;
  date: string;
  donorName: string;
  giftAidOptIn: string;
  quantity: number;
  totalValuation: string;
}

export type DonationsTableConfig = Array<{
  title: string;
  dataIndex: keyof DonationsTableRow;
  key: keyof DonationsTableRow;
}>;

export type DonorTableConfig = Array<{
  title: string;
  dataIndex: keyof DonorTableRow;
  key: keyof DonorTableRow;
}>;

export type UpdateSchoolFormSteps =
  | 'schoolDetails'
  | 'personalDetails'
  | 'billingDetails';

export type UpdateSchoolBillingDetails = Pick<
  SchoolFormUpdateType,
  | 'bankAccountName'
  | 'branchSortCode1'
  | 'branchSortCode2'
  | 'branchSortCode3'
  | 'accountNumber'
>;

export enum COLLECTION_STATUSES {
  'Successful' = 'SUCCESSFUL',
  'Unsuccessful' = 'UNSUCCESSFUL',
  'Pending' = 'PENDING',
}

export interface UpdateCampaignCollectionRulesType
  extends Pick<CampaignCreateType, 'collectionAttempts'> {}
