import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import AccountMenu from '../../../layout/Menu/AccountMenu';

interface DonorAccountMenuProps {
  accountName: string;
}

export default function DonorAccountMenu({ accountName }: DonorAccountMenuProps) {
  const { push } = useHistory();

  const onDonationCampaignsClick = () => push(ROUTER_CONFIG.DONOR_DASHBOARD.getPath());
  const onDeviceTradeinClick = () => console.log('device tradein');
  const onAccountSettingsClick = () => push(ROUTER_CONFIG.DONOR_SETTINGS.getPath());

  return (
    <AccountMenu
      onDonationCampaignsClick={onDonationCampaignsClick}
      onDeviceTradeinClick={onDeviceTradeinClick}
      onAccountSettingsClick={onAccountSettingsClick}
      accountName={accountName}
    />
  );
}
