import { SchoolPublicTypeLite } from '../schools/types';

export enum COLLECTION_PERRIODS {
  'monthly' = 'monthly',
  'quarterly' = 'quarterly',
  'annualy' = 'annualy',
  'oneoff' = 'oneoff',
}

export interface CampaignPublicType {
  _id: string;
  name: string;
  description: string;
  collectionPeriod: COLLECTION_PERRIODS;
  inviteCode: string;
  initialDonation?: number;
  numberOfCollections?: number;
  differentInitalDonation?: boolean;
  donationAmounts?: Array<number | undefined>;
  allowCustomAmounts?: boolean;
  startDate: string;
  freezed: Array<string>;
  owner: string;
  isFreezed: boolean;
  school: string | SchoolPublicTypeLite;
  isStudentsRefRequired?: boolean;
  isAuthorizationRequired?: boolean;
  collectionAttempts?: number;
  notificationsEmails?: Array<string | undefined>;
  collectionType?: string;
  giftAid?: boolean;
  collections?: Array<any>;
  activePaymentOption?: Array<any>;
  question?: string;
  contributionURL?: string;
  choices?: Array<string | undefined>;
}

export interface CollectionForm {
  collectionSchemeName: string;
  collectionFrequency: string;
  initialDonationAmount: number;
  recurringDonationAmounts: number;
  collectionPeriod: string;
  differentInitalDonation: boolean;
  numberOfCollections: number;
  initialDonation: string;
}

export interface PayOptType {
  collectionFrequency: string;
  collectionPeriod: string;
  collectionSchemeName: string;
  donationAmount: string;
  initialDonationAmount: number;
  numberOfCollections: number;
  recurringDonationAmounts: number;
  _id: string;
}

export type CampaignCreateType = Omit<
  CampaignPublicType,
  '_id' | 'inviteCode' | 'freezed' | 'owner' | 'isFreezed' | 'school'
>;

export type CreateCampignSchemaType = Omit<CampaignCreateType, 'startDate'> & {
  startDate: Date;
};

export type CreateCampignFields = Omit<
  CampaignPublicType,
  '_id' | 'freezed' | 'owner' | 'isFreezed' | 'startDate' | 'school'
> & { startDate: Date };

export interface InviteType {
  email: string;
  fullName?: string;
}

export interface CreateInvitesListType {
  members: Array<InviteType>;
  message?: string;
}

export interface FreezeDonorsType {
  donors: string[];
  toFreeze: boolean;
}

export type CampaignEditType = Partial<
  Omit<
    CampaignPublicType,
    | '_id'
    | 'inviteCode'
    | 'school'
    | 'startDate'
    | 'freezed'
    | 'owner'
    | 'isFreezed'
  > & {
    school: string;
    startDate: Date;
  }
>;
