import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  loadCampaignByInvite,
  selectCampaignInvite,
  selectCurrentCampaign,
  setCampaignInviteJoin,
} from '../../../../feature/campaignState';
import { getCurrentSchool } from '../../../../feature/schoolsState';
import { CampaignInviteAcceptType } from '../../../../project-types/campaign-join/types';
import { COLLECTION_PERRIODS } from '../../../../project-types/campaign/types';
import ROUTER_CONFIG from '../../../../router/router-config';
import DonorLayout from '../../layout/DonorSignupLayout';
import DonorInviteAccept from './DonorInviteAccept';
import { getUserProfile } from '../../../../feature/userState';

export default function DonorInviteAcceptPage() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { campaignId, inviteCode } = useParams<{
    campaignId: string;
    inviteCode: string;
  }>();

  const [students, setStudents] = useState(['']);

  useEffect(() => {
    dispatch(loadCampaignByInvite(campaignId, inviteCode));
    dispatch(
      setCampaignInviteJoin({
        donationAmount: 0,
      })
    );
  }, [campaignId, inviteCode]);

  const campaign = useSelector(selectCurrentCampaign);
  const currentSchool = useSelector(getCurrentSchool);
  const campaignJoin = useSelector(selectCampaignInvite);
  const profile = useSelector(getUserProfile);

  // console.log('campaign', campaign)

  const [customAmmount, setCustomAmmount] = useState<number | null>(null);

  const setDonationAmout = useCallback(
    (donationAmount: number) => {
      setCustomAmmount(null);
      dispatch(setCampaignInviteJoin({ donationAmount }));
    },
    [setCustomAmmount, dispatch]
  );

  // useEffect(() => {
  //   if (campaign?.collectionPeriod === COLLECTION_PERRIODS.oneoff && campaign.initialDonation) {
  //     dispatch(setCampaignInviteJoin({
  //       donationAmount: campaign.initialDonation,
  //     }));
  //   }

  //   // if (campaign && campaign.donationAmounts?.length === 1 && typeof campaign.donationAmounts[0] === 'number') {
  //   if (campaign && campaign.donationAmounts?.length && typeof campaign.donationAmounts[0] === 'number') {
  //     setDonationAmout(campaign.donationAmounts[0]);
  //   }
  // }, [campaign, dispatch, setDonationAmout]);

  const onUpdateCustomAmmount = (donationAmount: number) => {
    // debugger
    setCustomAmmount(donationAmount);
    dispatch(setCampaignInviteJoin({ donationAmount }));
  };

  const onNext = (students: CampaignInviteAcceptType['students'], choice: CampaignInviteAcceptType['choice'], studentNumber: CampaignInviteAcceptType['studentNumber']) => {
    // if (
    //   campaign?.collectionPeriod === COLLECTION_PERRIODS.oneoff &&
    //   campaign.initialDonation
    // ) {
    //   dispatch(
    //     setCampaignInviteJoin({
    //       donationAmount: campaign?.initialDonation,
    //       students,
    //     })
    //   );
    // }

    if (campaign) {
      dispatch(
        setCampaignInviteJoin({
          donationAmount: 0,
          // donationAmount: campaign.donationAmounts[0],
          students,
          choice,
          studentNumber,
        })
      );
    }

    if (profile && profile.donorProfile) {
      push(
        ROUTER_CONFIG.DONOR_SIGNUP_DIRECTPAY.getPath({
          campaignId: campaign!._id,
          inviteCode,
        }),
        { donorProfile: profile.donorProfile }
      );
    } else {
      push(
        ROUTER_CONFIG.DONOR_CREATE_FORM.getPath({
          campaignId: campaign!._id,
          inviteCode,
        })
      );
    }

    // push(
    //   ROUTER_CONFIG.DONOR_SIGNUP_PRIVACY.getPath({ campaignId, inviteCode })
    // );
  };

  const onTradeinClickHandler = () => console.log('tradein device');

  return (
    <DonorLayout>
      {campaign && currentSchool && (
        <DonorInviteAccept
          setCustomAmmount={onUpdateCustomAmmount}
          currentSchool={currentSchool}
          setDonationAmout={setDonationAmout}
          campaignJoin={campaignJoin}
          campaign={campaign}
          donationAmount={campaignJoin?.donationAmount}
          customAmmount={customAmmount}
          onNext={onNext}
          onTradeinClickHandler={onTradeinClickHandler}
        />
      )}
    </DonorLayout>
  );
}
