import styled from 'styled-components/macro';
import {
  ButtonDarkBlack,
  ButtonDark,
  ButtonLightGray,
  ButtonLight,
} from '../../../ui/Button';
import BackButton from '../../../ui/elements/BackButton';

interface InviteProps {
  onIviteEmail: () => void;
  onBack: () => void;
  onCopyToClipboard: () => void;
  numberOfInvites: number;
  onViewContactList: () => void;
}

export default function Invite({
  onViewContactList,
  numberOfInvites,
  onBack,
  onIviteEmail,
  onCopyToClipboard,
}: InviteProps) {
  return (
    <Wrap>
      <BackButton onClickHandler={onBack} />
      <Content>
        <MainHeader>Invite Contributors</MainHeader>
        <MainText>
          You have successfully created your campaign! Now either send invites
          to contributors or use an invite code in your existing communications
          with contributors.
        </MainText>
        <ButtonsWrap>
          <StyledButton onClick={onIviteEmail}>Email Contributors</StyledButton>
          or
          <StyledButton onClick={onCopyToClipboard}>
            Copy Invite Code
          </StyledButton>
        </ButtonsWrap>
        {/* {numberOfInvites > 0 && (
        <WrapInvite>
          <InviteText>
            You have invited
            {numberOfInvites}
            {' '}
            people to donate
          </InviteText>
          <ButtonViewContacts onClick={onViewContactList}>View contacts list</ButtonViewContacts>
        </WrapInvite>
        )} */}
      </Content>
    </Wrap>
  );
}

const Wrap = styled.main`
  padding-top: 44px;
  display: flex;
  gap: 34px;
  align-items: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 471px;
  display: flex;
  flex-direction: column;
`;

const MainHeader = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 6px;
  text-transform: none;
  color: #1f1f1f;
`;

const MainText = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #909da8;
  margin-bottom: 36px;
  width: 100%;
  max-width: 471px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
`;

const StyledButton = styled(ButtonDarkBlack)`
  padding: 11px 28px;
  font-weight: 400;
  white-space: nowrap;
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const ButtonBack = styled(ButtonLightGray)`
  width: 285px;
`;

const ButtonNewCampaign = styled(ButtonDark)`
  padding: 11px 28px;
  width: 285px;
`;

const WrapInvite = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 105px;
  border-radius: 13px;
  border: 1px solid #e0e0e0;
  padding: 10px 40px;
`;

const InviteText = styled.p`
  margin: 0;
  color: #000;
  font-size: 18px;
`;

const ButtonViewContacts = styled(ButtonLight)`
  padding: 11px;
  width: 248px;
`;
