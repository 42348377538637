import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { ButtonLight } from '../../../ui/Button';
import DropDown, { DropDownItem } from '../../../ui/DropDown';

import ROUTER_CONFIG from '../../../router/router-config';

interface MenuPublickType {
  className?: string;
}

export default function MenuPublick({ className }: MenuPublickType) {
  const history = useHistory();

  const schoolLogin = () => {
    history.push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());
  };

  const schoolSignUp = () => {
    history.push(ROUTER_CONFIG.CREATE_SCHOOL_PAGE.getPath());
  };

  const donorLogin = () => {
    // debugger;
    history.push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
  };

  return (
    <Menu className={className}>
      <MenuItemLogin>
        <DropDown>
          <DropDownItem title="School login" onClick={schoolLogin} />
          <DropDownItem title="Parent login" onClick={donorLogin} />
        </DropDown>
      </MenuItemLogin>
      <SignUpButton onClick={schoolSignUp}>
        Register
      </SignUpButton>
    </Menu>
  );
}

const Menu = styled.nav`
  display: flex;
  width: 50%;
  max-width: 540px;
  align-items: center;
`;

const MenuItem = styled.a`
  font-size: 14px;
  line-height: 17px;
  margin-left: 32px;
`;

const MenuItemLogin = styled(MenuItem)`
  flex-grow: 2;
  text-align: right;
`;

const SignUpButton = styled(ButtonLight)`
  padding: 9px 24px;
  margin-left: 30px;
`;
