import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {ManageTableModel} from "../../../school/types";
import styled from "styled-components/macro";

interface OverviewTableProps {
    tableData: Array<ManageTableModel>;
    selectedRows: Array<string>;
    setSelectedRows: (deviceIds: Array<string>) => void
}

export default function OverviewManageTable({ tableData, selectedRows, setSelectedRows }: OverviewTableProps) {
    const tableConfig: ColumnsType<ManageTableModel> = [
        {
            title: 'First Collection Date',
            dataIndex: 'firstCollectionDate',
            key: 'firstCollectionDate',
        },
        {
            title: 'Contributor Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Student ID',
            dataIndex: 'students',
            key: 'students',
            render: (val) =><span>{val + ' '}</span>,
        },
        // {
        //     title: 'Collection Type',
        //     dataIndex: 'collectionType',
        //     key: 'collectionType',
        // },
        {
            title: 'Gift Aid Registered',
            dataIndex: 'giftAidRegistered',
            key: 'giftAidRegistered',
        },
        {
            title: 'Initial Сollection Amount',
            dataIndex: 'initialDonationAmount',
            key: 'initialDonationAmount',
        },
        {
            title: 'Recurring collection amounts',
            dataIndex: 'donationAmount',
            key: 'donationAmount',
        },
        {
            title: 'Time Period',
            dataIndex: 'timePeriod',
            key: 'timePeriod',
        },
        {
            title: 'Number of collections',
            dataIndex: 'donationsNumber',
            key: 'donationsNumber',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) =><Span>{text}</Span>,
        },
        {
            title: 'Notes',
            dataIndex: 'note',
            key: 'note',
        },
    ];

    return (
        <Table
            rowSelection={{
                type: 'checkbox',
                onChange: (ids) => setSelectedRows(ids as Array<string>),
                selectedRowKeys: selectedRows,
            }}
            columns={tableConfig}
            dataSource={tableData}

        />
    );
}

const Span = styled.span`
  font-weight: bold;
`;
