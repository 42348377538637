import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { signInSchool } from '../../../feature/schoolsState';
import { getUserProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import { SchoolLoginFields } from '../../school/types';
import Login from '../../school/school-login/SchoolLogin';
import DonorLayout from '../layout/DonorLayout';
import MenuPublick from '../../layout/Menu/MenuPublick';

export default function DonorLoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const fromState = history.location.state as { from: Location } | undefined;

  const profile = useSelector(getUserProfile);
  console.log('history', useHistory())
  const onSignIn = (schoolLoginFields: SchoolLoginFields) => {
    setLoading(true)
    dispatch(signInSchool(schoolLoginFields, setLoading));
  };

  const onForgotPasswordClickHandler = () => history.push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath());

  useEffect(() => {
    if (profile?.donorProfile) {
      if (fromState && fromState.from) {
        history.replace(fromState.from);
      } else {
        history.push(ROUTER_CONFIG.DONOR_DASHBOARD.getPath());
      }
    }
  }, [profile, history, fromState]);

  return (
    <DonorLayout headerLoginLinkTo={ROUTER_CONFIG.DONOR_LOGIN.path} menu={<MenuPublick />}>
      <Login onForgotPasswordClickHandler={onForgotPasswordClickHandler} title="Parent login" onSignIn={onSignIn} loading={loading}/>
    </DonorLayout>
  );
}

// const Accept
