import { useHistory } from 'react-router';
import { restorePassword } from '../../feature/userState';
import ForgotPasswordForm from './ForgotPasswordForm';
import ROUTER_CONFIG from '../../router/router-config';
import SchoolLayout from '../school/layout/SchoolLayout';
import LoginLink from '../layout/Menu/LoginLink';

export default function ForgotPasswordPage() {
  const history = useHistory();

  const onSuccessRestore = () => history.push(ROUTER_CONFIG.FORGOT_PASSWORD_COMPLETE_PAGE.getPath());
  const onFormSubmitHandler = (email: string) => restorePassword(email, onSuccessRestore);

  const onLoginClickHandler = () => {
    if (history.location.state) {
      const { state } = history.location;

      if ((state as any).loginPage) {
        switch ((state as any).loginPage) {
          case 'school':
            return history.push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());
          case 'donor':
            return history.push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
          default:
            break;
        }
      }
    }

    return history.push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());
  };

  return (
    <SchoolLayout menu={<LoginLink onLoginClick={onLoginClickHandler} />} headerLoginLinkTo={ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.path}>
      <ForgotPasswordForm onFormSubmitHandler={onFormSubmitHandler} />
    </SchoolLayout>
  );
}
