import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import ROUTER_CONFIG from '../../../../router/router-config';
import LogoLayout from '../../../layout/LogoLayout';
import FundraisingOverview from './FundraisingOverview';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { selectSchoolFundraisingData, loadSchoolFundraisingData } from '../../../../feature/schoolsState';
import { showPriceDigits, formatDateString } from '../../../../utils/formats';

export interface TableRow {
  key: string;
  name: string;
  startDate: string;
  endDate: string;
  totalAmountExpected: string;
  totalAmountCollected: string;
  totalAmountNotCollected: string;
  totalGiftAidAmount: string;
  platformPayouts: string;
}

export type TableColumns = Array<{ title: string; dataIndex: keyof TableRow; key: keyof TableRow; }>;

export default function FundraisingOverviewPage() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const campaignList = useSelector(selectSchoolFundraisingData);

  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState<[string, string]>(['', '']);

  useEffect(() => {
    const startDate = selectedCollectionPeriod[0] ? formatDateString(selectedCollectionPeriod[0]) : '';
    const endDate = selectedCollectionPeriod[1] ? formatDateString(selectedCollectionPeriod[1], true) : '';

    dispatch(loadSchoolFundraisingData(startDate, endDate));
  }, [dispatch, selectedCollectionPeriod]);

  const tableRows: Array<TableRow> = campaignList.map(
    (campaign) => ({
      key: campaign.campaignName,
      name: campaign.campaignName,
      startDate: formatDateDDMMMYYYY(new Date(campaign.startDate)),
      endDate: campaign.endDate !== '∞' ? formatDateDDMMMYYYY(new Date(campaign.endDate)) : '∞',
      totalAmountExpected: `£ ${showPriceDigits(+campaign.totalAmountCollected)}`,
      totalAmountCollected: `£ ${showPriceDigits(campaign.totalAmountCollected)}`,
      totalAmountNotCollected: `£ ${showPriceDigits(+campaign.totalAmountCollected)}`,
      totalGiftAidAmount: `£ ${showPriceDigits(campaign.totalAmountCollected * 0.25)}`,
      platformPayouts: '-',
    }),
  );

  const onBack = () => {
    if (campaignList.length > 0) {
      push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_INFO.getPath({ campaignId: campaignList[0]._id }));
    } else {
      push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_CREATE.getPath());
    }
  };

  return (
    <LogoLayout>
      <FundraisingOverview onChangeTimePeriod={setSelectedCollectionPeriod} tableRows={tableRows} onBack={onBack} />
    </LogoLayout>
  );
}
