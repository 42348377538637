import { DatePicker } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { COLLECTION_STATUSES } from '../../../types';
import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import { SecondHeader } from '../../../../../ui/Headers';
import Input from "../../../../../ui/forms/Input";

const { RangePicker } = DatePicker;

const CollectionStatuses = ['All', ...Object.keys(COLLECTION_STATUSES)];

interface CollectionReportProps {
  onChangeFilter: (filter: keyof typeof COLLECTION_STATUSES | null) => void;
  onChangeTimePeriod: (period: [string, string]) => void;
  nameFilter: string;
  setNameFilter: (name: string) => void;
}

export default function CollectionReport({ onChangeFilter, onChangeTimePeriod, nameFilter, setNameFilter }: CollectionReportProps) {
  const [typeOfCollection, setTypeOfCollection] = useState<(typeof CollectionStatuses)[number]>('All');

  const onFilterClickHandler = (status: string) => {
    if (status === 'All') {
      onChangeFilter(null);
    } else {
      onChangeFilter(status as keyof typeof COLLECTION_STATUSES);
    }
  };

  return (
    <>
      <SecondHeader>Collections Report</SecondHeader>
      <InputStyled placeholder="Find by name:" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
      <Filter>
        <div>
          Collection status:
          <FilterGroup value={typeOfCollection} onChange={(e) => setTypeOfCollection(e.target.value)}>
            {CollectionStatuses.map((status) => (
              <FilterItem onClick={() => onFilterClickHandler(status)} value={status}>{status}</FilterItem>
            ))}
          </FilterGroup>
        </div>
        <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} picker="month" />
      </Filter>
    </>
  );
}

const Filter = styled.div`
  //margin-top: 41px;
  margin-top: 21px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroup = styled(RadioGroup)`
  margin-left: 14px;
`;

const FilterItem = styled(RadioItem)`
  margin-left: 18px;
`;

const InputStyled = styled(Input)`
  margin-top: 10px;
  width: 255px;
  height: 34px;
  margin-right: 20px;
  background: #FFFFFF;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 25px 0 25px;
`;
