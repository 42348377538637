import React, {FC, useEffect, useState} from 'react';
import styled from "styled-components/macro";
import {DownOutlined} from '@ant-design/icons';
import {Dropdown, Menu, Typography} from "antd";
import {ManageTableModel} from "../../../school/types";
import OverviewManageTable from "./OverviewManageTable";
import {
    deleteManageDonors, loadCampaignDonors,
    loadManageCollections,
    selectManageDonors,
    updateManageDonor
} from "../../../../feature/campaignState";
import {useDispatch, useSelector} from "react-redux";
import {formatDateDDMMMYYYY} from "../../../../ui/DateFormatMethods";

interface DeviceTradeinProps {
    campaign: any;
    campaignId: string;
}


export default function ManageDonorsTable({campaign, campaignId}: DeviceTradeinProps) {
    const dispatch = useDispatch();
    const tableList = useSelector(selectManageDonors);

    const [selectedRows, setSelectedRows] = useState<Array<string>>([]);

    useEffect(() => {
        dispatch(loadManageCollections(campaignId,  'false'));
        // dispatch(loadManageCollections(campaignId,  (campaign?.isAuthorizationRequired).toString()));
    }, [campaignId, dispatch]);

    // console.log('campaign', campaign?.isAuthorizationRequired)
    // console.log('testList', testList)
    // const tableData: Array<TestTableModel> = testList.donorsList
    const tableData: Array<ManageTableModel> = tableList?.donorsList.map((table) => ({
        key: table._id,
        firstCollectionDate: formatDateDDMMMYYYY(new Date(table.firstCollectionDate)),
        fullName: table.fullName,
        students: table.students,
        collectionType: table.collectionType,
        giftAidRegistered: table.giftAidRegistered == false ? 'No' : 'Yes',
        donationAmount: '£ ' + table.payments.paymentOption.recurringDonationAmounts,
        initialDonationAmount: '£ ' + table.payments.paymentOption.initialDonationAmount,
        collectionsAmounts: table.collectionsAmounts,
        timePeriod: table.payments.paymentOption.collectionFrequency,
        donationsNumber: table.donationsNumber,
        status: table.authorized == false ? 'pending approval' : 'approved',
        note: table.choice ? table.choice : 'No notes',
    }));

    const onUpdateClickHandler = () => {
        if (selectedRows.length === 0) return;
        selectedRows.map(row => dispatch(updateManageDonor(campaignId, row, true)))
        setTimeout(() => {
            dispatch(loadManageCollections(campaignId,  'false'));
            dispatch(loadCampaignDonors(campaignId, 'true'));
        }, 1000);

        setSelectedRows([]);
    };

    const onDeleteClickHandler = () => {
        if (selectedRows.length === 0) return;
        setTimeout(() => {
            dispatch(deleteManageDonors(campaignId,  (campaign?.isAuthorizationRequired).toString(), selectedRows,));
            dispatch(loadManageCollections(campaignId,  'false'))
        }, 1000)
        setSelectedRows([]);
    };

    const [namePre, setNamePre] = useState('')
    // console.log('selectedRows', selectedRows)
    const dropDownContent = (
        <Menu>
            <Menu.Item onClick={onUpdateClickHandler}>
                Confirm
            </Menu.Item>
            <Menu.Item onClick={onDeleteClickHandler}>
                Remove
            </Menu.Item>
        </Menu>
    );

    const filtered = tableData.filter(d => d.fullName.toLowerCase().includes(namePre.toLowerCase()))

    return (<>
            <Controls>
                <SecondHeader>Collection Pre-authorisation</SecondHeader>
                <Dropdown overlay={dropDownContent}>
                    <StyledLink className="ant-dropdown-link" onClick={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}>
                        Actions
                        {' '}
                        <DownOutlined />
                    </StyledLink>
                </Dropdown>
            </Controls>
            <InputStyled placeholder="Find by name:" value={namePre} onChange={(e) => setNamePre(e.target.value)}/>
            <StyledOverviewTable tableData={namePre ? filtered : tableData} selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
        </>
    );
}

const StyledOverviewTable = styled(OverviewManageTable)`
  margin-top: 100px;
  border-top: 2px solid #2682DE;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 15px;
  margin-top: 50px;
`;

const StyledLink:FC<any> = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2682DE;
  cursor: pointer;
`;

export const SecondHeader = styled.h2`
  font-weight: 750;
  font-size: 18px;
  line-height: 26px;
`;

const InputStyled = styled.input`
  width: 255px;
  height: 34px;
  //margin-right: 20px;
  margin-bottom: 15px;
  background: #FFFFFF;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 25px 0 25px;
`;
