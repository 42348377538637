//@ts-nocheck
import React from 'react';
import styled from 'styled-components/macro';
import SelectPaymentOption from './SelectPaymentOption';
import {Radio, Space} from "antd";
import Input from "../../../../../ui/forms/Input";

interface ChoicesFormProps {
    choices: any;
    question: any;
    choice: string;
    setChoice: (string) => void;
}

export default function QuestionForm(
    {
        choices,
        question,
        choice,
        setChoice
    }: ChoicesFormProps
) {


    const onChange = (event: any) => {
        setChoice(event.target.value);
    };

    return (
        <Container>
            <RowItem>
                <NumberInSquare>7</NumberInSquare>
                <RowContentWrapper>
                    <Title>{question}*</Title>
                    {choices.map((choi) => (
                    <RowContent key={choi}>
                        <Row>
                            <Radio.Group onChange={onChange} value={choice}>
                                <Space direction="vertical">
                                        <RadioContainer
                                            // active={activeId === payment.value}
                                        >
                                            <Radio value={choi}>{choi}</Radio>
                                        </RadioContainer>
                                </Space>
                            </Radio.Group>
                        </Row>
                    </RowContent>
                    ))}
                    {choices.length < 1 &&
                        <div>
                            <InputStyled value={choice} onChange={onChange} placeholder="Enter your answer:" />
                        </div>}


                </RowContentWrapper>
            </RowItem>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 28px;
  background: rgba(245, 248, 251, 0.7);
`;

const RadioContainer = styled.div<{ active: boolean }>`
  border: 1.3px solid ${(props) => (props.active ? '#2682DE' : '#e0e0e0')};
  border-radius: 5px;

  background: #ffffff;

  padding: 7px;

  width: 450px;
`;

const RowItem = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #e8f4ff;
  color: #2682de;
`;

const RowContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  gap: 44px;
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;
  margin-top: 10px;
`;
