import { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components/macro';

interface InputWrapProps {
  title: string
  children: ReactNode
  hasError?: boolean
  isRequired?: boolean
  style?: CSSProperties
}

export default function InputWrap({
  title, children, hasError, isRequired, style = {},
}: InputWrapProps) {
  return (
    <InputWrapBlock style={style} hasError={hasError}>
      <Label isRequired={isRequired}>
        {title}
      </Label>
      {children}
    </InputWrapBlock>
  );
}

const InputWrapBlock = styled.div<{ hasError?: boolean }>`
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 24px;
  & input, textarea {
    border-color: ${(props) => (props.hasError ? 'red' : 'default')}
  }
`;

const Label = styled.label<{ isRequired?: boolean }>`
  font-size: 16px;
  color: #102F54;

  &::after {
    ${({ isRequired }) => isRequired && `
    content: '*';
    padding-left: 2px;
    color: red;`
}
  }
`;
