//@ts-nocheck
import React, { ChangeEvent, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import Input from '../../../../../ui/forms/Input';

import ArrowIcon from '../../../../../img/icons/arrow-icon-blue.svg';
import { showPriceDigits } from '../../../../../utils/formats';
import SelectPaymentOption from './SelectPaymentOption';
import {
  setActivePaymentOption,
  setPayOpt,
} from '../../../../../feature/campaignState';

interface StudentsFormProps {
  students: Array<string>;
  collections: Array<any>;
  onIncButtonClickHandler: () => void;
  onDecButtonClickHandler: () => void;
  onChangeStudents: (newStudents: Array<string>) => void;
  isIdRequired: boolean;
  initialDonationAmount: number;
  recurringDonationAmount: number;
}

export default function StudentsForm({
  students,
  onChangeStudents,
  onIncButtonClickHandler,
  onDecButtonClickHandler,
  isIdRequired,
  initialDonationAmount,
  recurringDonationAmount,
  collections,
}: StudentsFormProps) {
  const [activeCollectionId, setActiveCollectionId] = React.useState(
    collections[0]._id
  );

  const [activeCollection, setActiveCollection] = React.useState<any>({});

  const dispatch = useDispatch();

  // console.log('col', collections);

  const onChangeHandler = (key: number) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [
      ...students.slice(0, key),
      e.target.value,
      ...students.slice(key + 1),
    ];

    onChangeStudents(newValues);
  };

  const onChange = (event: any) => {
    setActiveCollectionId(event.target.value);
  };

  useEffect(() => {
    const collection = collections.find(
      (collection) => collection._id === activeCollectionId
    );
    setActiveCollection(collection);
    dispatch(setPayOpt(collection));
    dispatch(setActivePaymentOption(collection));
  }, [activeCollectionId]);

  useEffect(() => {
    setActiveCollectionId(collections[0]._id);
  }, []);

  return (
    <Container>
      <RowItem>
        <NumberInSquare>1</NumberInSquare>
        <RowContentWrapper>
          <Title>Select A Payment Option*</Title>
          <RowContent>
            <Row>
              <SelectPaymentOption
                collections={collections}
                onChange={onChange}
                activeId={activeCollectionId}
              />
            </Row>
          </RowContent>
        </RowContentWrapper>
      </RowItem>

      <RowItem style={{ marginTop: 20 }}>
        <NumberInSquare>2</NumberInSquare>
        <RowContentWrapper>
          <Title>How many students would you like to register for?</Title>
          <RowContent>
            <LabelBold>Number of Students</LabelBold>
            <Row>
              <NumberInputBlock>
                <NumberInput>
                  <NumberInputDisplay>{students.length}</NumberInputDisplay>
                  <NumberInputControls>
                    <IncButton onClick={onIncButtonClickHandler} />
                    <DecButton onClick={onDecButtonClickHandler} />
                  </NumberInputControls>
                </NumberInput>
              </NumberInputBlock>
              <InfoBlock>
                {/* <InfoBlockItem>
                  <Text>Your Initial Donation</Text>
                  <BoldText>{`£${showPriceDigits(
                    initialDonationAmount * students.length
                  )}`}</BoldText>
                </InfoBlockItem> */}
                <InfoBlockItem>
                  <Text>
                    {activeCollection.collectionFrequency === 'oneoff'
                      ? `Your one-off contribution`
                      : `Your ${activeCollection.collectionFrequency}${' '}
                    recurring contribution`}
                  </Text>
                  <BoldText>{`£${showPriceDigits(
                    activeCollection.recurringDonationAmounts! * students.length
                  )}`}</BoldText>
                </InfoBlockItem>
              </InfoBlock>
            </Row>
          </RowContent>
        </RowContentWrapper>
      </RowItem>
      {isIdRequired && (
        <RowItem style={{ marginTop: 20 }}>
          <NumberInSquare>3</NumberInSquare>
          <StudentInputsRowWrapper>
            <Title>Please enter the student’s ID(s)</Title>
            <GrayText>
              This helps the school link the collection to the relevant student
            </GrayText>
            <Inputs>
              {students.map((s, i) => (
                <InputBlock key={i}>
                  <InputLabel>{`Student #${i + 1} ID`}</InputLabel>
                  <StyledTextInput value={s} onChange={onChangeHandler(i)} />
                </InputBlock>
              ))}
            </Inputs>
          </StudentInputsRowWrapper>
        </RowItem>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 28px;
  background: rgba(245, 248, 251, 0.7);
`;

const RowItem = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #e8f4ff;
  color: #2682de;
`;

const RowContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  gap: 44px;
`;

const NumberInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LabelBold = styled.span`
  font-size: 12px;
  color: #102f54;
  font-weight: bold;
`;

const Text = styled.span`
  font-size: 14px;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GrayText = styled(Text)`
  color: #6b7a87;
  margin-top: 5px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
  min-width: 350px;
  background-color: #f2f2f2;
  border: 1px solid #ebecef;
  border-radius: 4px;
`;

const InfoBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NumberInput = styled.div`
  display: flex;
  max-width: 150px;
  border-radius: 4px;
  overflow: hidden;
`;

const NumberInputDisplay = styled.div`
  padding: 8px 50px;
  color: #3f5d8d;
  font-weight: bold;
  background-color: #fff;
`;

const NumberInputControls = styled.div`
  display: flex;
  flex-direction: column;
`;

const IncButton = styled.div`
  background: url(${ArrowIcon}) no-repeat center center;
  background-color: #f2f2f2;
  width: 31px;
  height: 19px;
  cursor: pointer;

  &:hover {
    background-color: #bdbdbd;
  }
`;

const DecButton = styled(IncButton)`
  transform: rotate(180deg);
`;

const StudentInputsRowWrapper = styled(RowContentWrapper)`
  gap: unset;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 23px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const InputLabel = styled(LabelBold)`
  &::after {
    content: '*';
    padding-left: 2px;
    color: red;
  }
`;

const StyledTextInput = styled(Input)``;
