import * as yup from 'yup';
import { billingSchemaFieldsOptional } from '../../project-types/billing/schemas';
import { campaignSchema } from '../../project-types/campaign/schemas';
import { schoolCreateSchema } from '../../project-types/schools/schemas';
import { SchoolRegisterType } from '../../project-types/schools/types';
import { signUpSchema } from '../../project-types/users/schemas';
import {
  SchoolFormUpdateType, SignUpSchoolForm, UpdateCampaignCollectionRulesType, UpdateSchoolBillingDetails, UpdateSchoolFormSteps,
} from './types';

type SchoolCreateFieldsForSchema = Omit<SignUpSchoolForm, 'adress2' | 'adress3'>
  & { adress2?: string, adress3?: string }

export const signUpSchoolSchema: yup.SchemaOf<SchoolCreateFieldsForSchema> = yup.object({
  agree: yup.boolean().required().oneOf([true]),
  confirmPassword: yup.string().required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  schoolName: yup.string().required(),
  URN: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required(),
  postcode: yup.string().required(),
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().required(),
  logo: yup.string().optional(),
  country: yup.string().required(),

})
  .concat(signUpSchema);

// export const signUpSchoolSchema: yup.SchemaOf<SchoolCreateFieldsForSchema> = yup.object({
//   agree: yup.boolean().required().oneOf([true]),
//   confirmPassword: yup.string().required()
//     .oneOf([yup.ref('password'), null], 'Passwords must match'),
// })
//   .concat(schoolCreateSchema)
//   .concat(signUpSchema);

type SchoolUpdateFieldsForSchema = Omit<SchoolFormUpdateType, 'adress2' | 'adress3' | 'logo'>
  & { adress2?: string, adress3?: string, logo?: string };

// TODO Dirty hack
export const updateSchoolSchema: yup.SchemaOf<SchoolUpdateFieldsForSchema> = yup.object({
  schoolName: yup.string().required(),
  // URN: yup.string().required(),
  postcode: yup.string().required().required(),
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  logo: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required(),
  bankAccountName: yup.string().required(),
  branchSortCode1: yup.string().required(),
  branchSortCode2: yup.string().required(),
  branchSortCode3: yup.string().required(),
  accountNumber: yup.string().required(),
});

export const updateSchoolPersonalDetailsSchema: yup.SchemaOf<Pick<SchoolUpdateFieldsForSchema, 'firstName' | 'lastName'>> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const updateSchoolDetailsSchema: yup.SchemaOf<Pick<SchoolUpdateFieldsForSchema, 'schoolName' | 'postcode' | 'adress1' | 'adress2' | 'adress3' | 'town' | 'country' | 'phoneNumber'>> = yup.object({
  schoolName: yup.string().required(),
  postcode: yup.string().required().required(),
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  phoneNumber: yup.string().required(),
});

export const updateSchoolBillingDetails: yup.SchemaOf<UpdateSchoolBillingDetails> = yup.object({
  bankAccountName: yup.string().required(),
  branchSortCode1: yup.string().required(),
  branchSortCode2: yup.string().required(),
  branchSortCode3: yup.string().required(),
  accountNumber: yup.string().required(),
});

export const UpdateSchoolStepsSchema = {
  billingDetails: updateSchoolBillingDetails,
  personalDetails: updateSchoolPersonalDetailsSchema,
  schoolDetails: updateSchoolDetailsSchema,
};

export const CampaignCollectionRulesSchema: yup.SchemaOf<UpdateCampaignCollectionRulesType> = yup.object({
  collectionAttempts: yup.number().optional(),
});
