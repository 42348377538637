import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import { DonationsTableRow } from '../../../types';

const tableConfig: ColumnsType<DonationsTableRow> = [
  {
    title: 'Posted Date',
    dataIndex: 'postedDate',
    key: 'postedDate',
  },
  {
    title: 'Contributor Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => text[0] + text.slice(1).toLowerCase(),
  },
  {
    title: 'Student Reference(s)',
    dataIndex: 'studentReferences',
    key: 'studentReferences',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Gift Aid Amount',
    dataIndex: 'giftAidAmount',
    key: 'giftAidAmount',
  },
];

interface CollectionsTableProps {
  tableData: Array<DonationsTableRow>;
}

export default function CollectionTable({ tableData }: CollectionsTableProps) {
  return (<Table<DonationsTableRow> dataSource={tableData} columns={tableConfig} style={{ borderTop: '2px solid #2682DE' }} />);
}
