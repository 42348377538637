import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import WelcopmePage from "./components/welcome/WelcomePage";
import ROUTER_CONFIG from "./router/router-config";


export default function AppRouter() {
  return (
    <Router>
      <Switch>
        {Object.entries(ROUTER_CONFIG).map(([key, route]) => (
          <Route exact={route.exact} path={route.path} key={key}>
            {route.children}
          </Route>
        ))}
      </Switch>
    </Router>
  );
}