import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { request, setBearerToken } from '../api';
import {
  ApiAdminDeleteDonors,
  ApiAdminFreezeDonors,
  ApiGetAdminDonorsList,
  ApiGetDonorItem,
} from '../project-types/admin-donors/api-types';
import { DonorAdminType } from '../project-types/admin-donors/type';
import {
  ApiAdminDeleteCampaigns,
  ApiAdminFreezeCampaigns,
  ApiGetAdminCampaignsListBySchool,
} from '../project-types/admin-campaigns/api-types';
import { CampaignInviteAcceptType } from '../project-types/campaign-join/types';
import {
  ApiCampaignList,
  ApiCreateCampaign,
  ApiGetCampaignByInvite,
  ApiGetOneCampaign,
  ApiSendInvites,
  ApiFreezeDonors,
  ApiCampaignRemoveDonors,
  ApiCampaignUpdate,
  ApiGetExportFile,
} from '../project-types/campaign/api-types';
import {
  ApiGetCampaignDonations,
  ApiGetCampaignDonorsPaymentFields,
  ApiGetManageFields,
  ApiManageDonorUpdate,
} from '../project-types/campaign-payment/api-types';
import {
  CampaignEditType,
  CampaignPublicType,
  FreezeDonorsType,
  PayOptType,
} from '../project-types/campaign/types';
import { FreezeAdminCampaignsType } from '../project-types/admin-campaigns/types';
import { createAxiosConfig } from '../project-types/common/axios';
import { HTTP_METHODS } from '../project-types/common/http-types';
import {
  ApiCreateDonor,
  ApiDeleteDonor,
  ApiJoinCampaign,
  ApiDeleteCampignForDonor,
} from '../project-types/donor/api-types';
import { CampaignForDonor } from '../project-types/donor/types';
import {
  DonorPaymentStatsType,
  DonorCampaignSumRow,
} from '../project-types/donor-payment/types';
import {
  PaymentPublicType,
  DonorPaymentFields,
  PaymentWithStudentRefsType,
} from '../project-types/payment/types';
import {
  ApiGetDonationsMade,
  ApiGetDonorCampaignsSum,
} from '../project-types/donor-payment/api-types';
import { AppThunk, RootState } from '../store';
import { setCurrentSchool } from './schoolsState';
import { CampaignPaymentStatsType } from '../project-types/campaign-payment/types';

type СampaignsListState = Array<CampaignPublicType>;
type DonorsListState = Array<DonorPaymentFields>;
type AdminDonorsListState = Array<DonorAdminType>;
type DonorCampaignsListState = Array<DonorCampaignSumRow>;
type AdminCampaignsListState = Array<CampaignPublicType>;
type CurrentCampaignState = CampaignPublicType | null;
type PayOptState = PayOptType | null;
type CampaignInviteJoinState = CampaignInviteAcceptType;
type AdminCurrentDonorState = DonorAdminType | null;
type DonorCollectionsState = {
  donationsList: Array<PaymentPublicType>;
  stats: DonorPaymentStatsType | null;
};
type CurrentCampaignCollectionsState = {
  donationsList: Array<PaymentWithStudentRefsType>;
  stats: CampaignPaymentStatsType | null;
};
type CurrentTestCollectionsState = { donorsList: Array<any> };

const initialCampaignListState: СampaignsListState = [];
const initialCurrentCampaign: CurrentCampaignState = null;
const initialPayOpt: PayOptState = null;
const initialDonorListState: DonorsListState = [];
const initialAdminDonorsListState: AdminDonorsListState = [];
const initialAdminCampaignsListState: AdminCampaignsListState = [];
const initialDonorCampaignsListState: DonorCampaignsListState = [];
const initialAdminCurrentDonorState: AdminCurrentDonorState = null;
const initialDonorCollectionsState: DonorCollectionsState = {
  donationsList: [],
  stats: null,
};
const initialCurrentCampaignCollectionsState: CurrentCampaignCollectionsState = {
  donationsList: [],
  stats: null,
};
const initialCurrentTestCollectionsState: CurrentTestCollectionsState = {
  donorsList: [],
};

const initialCampaignInviteJoinState: CampaignInviteJoinState = {
  donationAmount: 0,
  students: [],
};

export const campaignList = createSlice({
  name: 'list',
  initialState: initialCampaignListState,
  reducers: {
    setList: (state, action: PayloadAction<Array<CampaignPublicType>>) => [
      ...action.payload,
    ],
  },
});

export const donorList = createSlice({
  name: 'donorList',
  initialState: initialDonorListState,
  reducers: {
    setDonorList: (state, action: PayloadAction<Array<DonorPaymentFields>>) => [
      ...action.payload,
    ],
  },
});

export const manageList = createSlice({
  name: 'manageList',
  initialState: initialCurrentTestCollectionsState,
  reducers: {
    setManageList: (state, action) => action.payload,
  },
});

export const adminDonorsList = createSlice({
  name: 'adminDonorsList',
  initialState: initialAdminDonorsListState,
  reducers: {
    setAdminDonorsList: (
      state,
      action: PayloadAction<Array<DonorAdminType>>
    ) => [...action.payload],
  },
});

export const donorCampaignsList = createSlice({
  name: 'donorCampaignsList',
  initialState: initialDonorCampaignsListState,
  reducers: {
    setDonorCampaignsList: (
      state,
      action: PayloadAction<Array<DonorCampaignSumRow>>
    ) => [...action.payload],
  },
});

export const donorCollections = createSlice({
  name: 'donorCollections',
  initialState: initialDonorCollectionsState,
  reducers: {
    setDonorCollections: (
      state,
      action: PayloadAction<ApiGetDonationsMade['successResponse']>
    ) => action.payload,
  },
});

export const adminCampaignsList = createSlice({
  name: 'adminCampaignsList',
  initialState: initialAdminCampaignsListState,
  reducers: {
    setAdminCampaignsList: (
      state,
      action: PayloadAction<Array<CampaignPublicType>>
    ) => [...action.payload],
  },
});

export const currentCampaign = createSlice({
  name: 'campaign',
  initialState: initialCurrentCampaign as CurrentCampaignState,
  reducers: {
    setCampaign: (state, action: PayloadAction<CampaignPublicType>) => {
      return action.payload;
    },

    setActivePaymentOption: (state, action) => {
      state!.activePaymentOption = action.payload;
    },
  },
});

export const payOpt = createSlice({
  name: 'payOpt',
  initialState: initialPayOpt as PayOptState,
  reducers: {
    setPayOpt: (state, action: PayloadAction<PayOptType>) => {
      return action.payload;
    },

    // setActivePaymentOption: (state, action: any) => {
    //     state!.activePaymentOption = action.payload;
    // },
  },
});

export const currentCampaignCollections = createSlice({
  name: 'campaignCollections',
  initialState: initialCurrentCampaignCollectionsState as CurrentCampaignCollectionsState,
  reducers: {
    setCampaignCollections: (
      state,
      action: PayloadAction<ApiGetCampaignDonations['successResponse']>
    ) => action.payload,
  },
});

export const adminCurrentDonor = createSlice({
  name: 'adminCurrentDonor',
  initialState: initialAdminCurrentDonorState as AdminCurrentDonorState,
  reducers: {
    setAdminCurrentDonor: (state, action: PayloadAction<DonorAdminType>) =>
      action.payload,
  },
});

export const campaignInviteJoin = createSlice({
  name: 'campaign-invite-join',
  initialState: initialCampaignInviteJoinState,
  reducers: {
    setCampaignInviteJoin: (
      state,
      action: PayloadAction<CampaignInviteJoinState>
    ) => action.payload,
  },
});

export const { setList } = campaignList.actions;
export const { setDonorList } = donorList.actions;
export const { setManageList } = manageList.actions;
export const { setAdminDonorsList } = adminDonorsList.actions;
export const { setDonorCampaignsList } = donorCampaignsList.actions;
export const { setDonorCollections } = donorCollections.actions;
export const { setAdminCampaignsList } = adminCampaignsList.actions;
export const { setCampaign, setActivePaymentOption } = currentCampaign.actions;
export const { setPayOpt } = payOpt.actions;
export const { setCampaignCollections } = currentCampaignCollections.actions;
export const { setAdminCurrentDonor } = adminCurrentDonor.actions;
export const { setCampaignInviteJoin } = campaignInviteJoin.actions;

const campaignState = combineReducers({
  list: campaignList.reducer,
  currentCampaign: currentCampaign.reducer,
  payOpt: payOpt.reducer,
  currentCampaignCollections: currentCampaignCollections.reducer,
  adminCurrentDonor: adminCurrentDonor.reducer,
  campaignInviteJoin: campaignInviteJoin.reducer,
  donorList: donorList.reducer,
  manageList: manageList.reducer,
  adminDonorsList: adminDonorsList.reducer,
  donorCampaignsList: donorCampaignsList.reducer,
  donorCollections: donorCollections.reducer,
  adminCampaignsList: adminCampaignsList.reducer,
});

export default campaignState;

export const loadCampaignList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiCampaignList>({
      url: ['/campaign'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiCampaignList['successResponse'] }) => {
    dispatch(setList(response.data.campaignList));
  });
};

export const loadDonorCampaignsList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetDonorCampaignsSum>({
      url: ['/payment', '/', 'donor', '/', 'campaigns'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetDonorCampaignsSum['successResponse'] }) => {
    dispatch(setDonorCampaignsList(response.data.campaignsList));
  });
};

export const loadAdminDonorsList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminDonorsList>({
      url: ['/admin/donor'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetAdminDonorsList['successResponse'] }) =>
    dispatch(setAdminDonorsList(response.data.donorsList))
  );
};
export const loadAdminCampaignsList = (schoolId: string): AppThunk => (
  dispatch
) => {
  request(
    createAxiosConfig<ApiGetAdminCampaignsListBySchool>({
      url: ['/admin/schools/', schoolId, '/campaigns'],
      method: HTTP_METHODS.GET,
    })
  ).then(
    (response: { data: ApiGetAdminCampaignsListBySchool['successResponse'] }) =>
      dispatch(setAdminCampaignsList(response.data.campaignsList))
  );
};

export const loadCampaignDonors = (
  campaignId: string,
  authorized: string
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetCampaignDonorsPaymentFields>({
      url: [
        '/payment',
        '/',
        'campaign',
        '/',
        campaignId,
        '/',
        'donors',
        '/',
        authorized,
      ],
      method: HTTP_METHODS.GET,
    })
  ).then(
    (response: {
      data: ApiGetCampaignDonorsPaymentFields['successResponse'];
    }) => dispatch(setDonorList(response.data.donorsList))
  );
};

export const loadManageCollections = (
  campaignId: string,
  authorised: string
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetManageFields>({
      url: [
        '/payment',
        '/',
        'campaign',
        '/',
        campaignId,
        '/',
        'donors',
        '/',
        authorised,
      ],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetManageFields['successResponse'] }) =>
    dispatch(setManageList(response.data))
  );
};

export const loadDonorCollections = (
  collectionStart: string,
  collectionEnd: string
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetDonationsMade>({
      url: ['/payment', '/', 'donor'],
      params: {
        collectionStart,
        collectionEnd,
        status: '',
      },
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetDonationsMade['successResponse'] }) =>
    dispatch(setDonorCollections(response.data))
  );
};

export const freezeDonors = (
  campaignId: string,
  donors: FreezeDonorsType['donors'],
  toFreeze: FreezeDonorsType['toFreeze']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiFreezeDonors>({
      url: ['/campaign', '/', campaignId, '/freeze'],
      method: HTTP_METHODS.PATCH,
      data: {
        donors,
        toFreeze,
      },
    })
  ).then((response: { data: ApiFreezeDonors['successResponse'] }) =>
    dispatch(setCampaign(response.data.campaign))
  );
};

export const adminFreezeDonors = (
  donorIds: Array<DonorAdminType['_id']>,
  toFreeze: boolean
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminFreezeDonors>({
      url: ['/admin/donor', '/freeze'],
      method: HTTP_METHODS.PATCH,
      data: { donorIds, toFreeze },
    })
  )
    .then(() => dispatch(loadAdminDonorsList()))
    .catch(() => message.error('Freezing error'));
};

export const adminFreezeDonor = (
  donorIds: Array<DonorAdminType['_id']>,
  toFreeze: boolean
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminFreezeDonors>({
      url: ['/admin/donor', '/freeze'],
      method: HTTP_METHODS.PATCH,
      data: { donorIds, toFreeze },
    })
  )
    .then(() => dispatch(loadAdminCurrentDonor(donorIds[0])))
    .catch(() => message.error('Freezing error'));
};

export const adminFreezeCampaigns = (
  schoolId: string,
  campignIds: FreezeAdminCampaignsType['campignIds'],
  toFreeze: FreezeAdminCampaignsType['toFreeze']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminFreezeCampaigns>({
      url: ['/admin/campaigns', '/freeze'],
      method: HTTP_METHODS.PATCH,
      data: {
        campignIds,
        toFreeze,
      },
    })
  )
    .then(() => dispatch(loadAdminCampaignsList(schoolId)))
    .catch(() => message.error('Freezing error'));
};

export const adminDeleteDonors = (
  donorIds: Array<DonorAdminType['_id']>
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminDeleteDonors>({
      url: ['/admin/donor'],
      method: HTTP_METHODS.DELETE,
      data: { donors: donorIds },
    })
  )
    .then(() => dispatch(loadAdminDonorsList()))
    .catch(() => message.error('Deletion error'));
};

export const deleteManageDonors = (
  campaignId: string,
  authorised: string,
  donors: ApiCampaignRemoveDonors['data']['donors']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiDeleteDonor>({
      url: ['/campaign/', campaignId, '/donors'] as any,
      method: HTTP_METHODS.DELETE,
      data: {
        donors,
      },
    })
  )
    .then((response: { data: ApiFreezeDonors['successResponse'] }) =>
      dispatch(loadManageCollections(campaignId, authorised))
    )
    .catch(() => message.error('Deletion error'));
};

export const adminDeleteCampaigns = (
  schoolId: string,
  campignIds: Array<CampaignPublicType['_id']>
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminDeleteCampaigns>({
      url: ['/admin/campaigns'],
      method: HTTP_METHODS.DELETE,
      data: { campaignIds: { campignIds } },
    })
  )
    .then(() => dispatch(loadAdminCampaignsList(schoolId)))
    .catch(() => message.error('Deletion error'));
};

export const deleteCampaignForDonor = (
  campaignId: CampaignPublicType['_id']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiDeleteCampignForDonor>({
      url: ['/donors', '/campaigns/', campaignId],
      method: HTTP_METHODS.DELETE,
    })
  ).then(() => dispatch(loadDonorCampaignsList()));
};

export const deleteDonors = (
  campaignId: string,
  donors: ApiCampaignRemoveDonors['data']['donors']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiDeleteDonor>({
      url: ['/campaign/', campaignId, '/donors'] as any,
      method: HTTP_METHODS.DELETE,
      data: {
        donors,
      },
    })
  ).then((response: { data: ApiFreezeDonors['successResponse'] }) =>
    dispatch(loadCampaignDonors(campaignId, 'true'))
  );
};

export const updateManageDonor = (
  campaignId: string,
  donorId: string,
  authorized: boolean
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiManageDonorUpdate>({
      url: ['/payment', '/', 'donor'],
      method: HTTP_METHODS.PATCH,
      data: {
        donor: donorId,
        campaign: campaignId,
        authorized: authorized,
      },
    })
  )
    .then((res) => {
      console.log(res);
      message.success('Campaign successfully updated');
    })
    .catch(() => message.error('Update error'));
};

export const loadCampaign = (
  campaignId: CampaignPublicType['_id']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetOneCampaign>({
      url: ['/campaign', '/', campaignId],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetOneCampaign['successResponse'] }) =>
    dispatch(setCampaign(response.data.campaign))
  );
};

export const loadExport = (campaignId: CampaignPublicType['_id']) => {
  request(
    createAxiosConfig<ApiGetExportFile>({
      url: ['/schools', '/export', '/campaign', '/', campaignId],
      method: HTTP_METHODS.GET,
    })
  ).then(
    (response) => {
      const { data, headers } = response;
      // const fileName = headers['content-disposition']?.replace(/\w+;filename=(.*)/, '$1')
      // const newFileName = fileName.substring(21)
      // console.log('FILE', response)
      // console.log('fileName', fileName);
      // console.log('newFileName', newFileName)

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv'); //or any other extension
      document.body.appendChild(link);
      console.log('link', link);
      link.click();
    }
    // response
  );
};

export const loadCampaignCollections = (
  campaignId: CampaignPublicType['_id'],
  collectionStart: string,
  collectionEnd: string,
  status: string
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetCampaignDonations>({
      url: ['/payment', '/', 'campaign', '/', campaignId],
      method: HTTP_METHODS.GET,
      params: {
        collectionStart,
        collectionEnd,
        status,
      },
    })
  ).then((response: { data: ApiGetCampaignDonations['successResponse'] }) =>
    dispatch(setCampaignCollections(response.data))
  );
};

export const loadAdminCurrentDonor = (donorId: string): AppThunk => (
  dispatch
) => {
  request(
    createAxiosConfig<ApiGetDonorItem>({
      url: ['/admin/donor', '/', donorId],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetDonorItem['successResponse'] }) =>
    dispatch(setAdminCurrentDonor(response.data.donorProfile))
  );
};

export const loadCampaignByInvite = (
  campaignId: CampaignPublicType['_id'],
  inviteCode: CampaignPublicType['inviteCode']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetCampaignByInvite>({
      url: ['/invite/', campaignId, '/', inviteCode],
      method: HTTP_METHODS.GET,
    })
  )
    .then((response: { data: ApiGetCampaignByInvite['successResponse'] }) => {
      dispatch(setCampaign(response.data.campaign));
      dispatch(setCurrentSchool(response.data.schoolProfile));
    })
    .catch(() => {
      message.error('invite error');
    });
};

export const createCampaign = (campaign: ApiCreateCampaign['data']) =>
  request(
    createAxiosConfig<ApiCreateCampaign>({
      url: ['/campaign'],
      data: campaign,
      method: HTTP_METHODS.POST,
    })
  ).then(
    (response: { data: ApiCreateCampaign['successResponse'] }) => response.data
  );

export const updateCampaign = (
  campaignId: string,
  campaign: CampaignEditType
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiCampaignUpdate>({
      url: ['/campaign', '/', campaignId],
      method: HTTP_METHODS.PATCH,
      data: campaign,
    })
  )
    .then((res: { data: ApiCampaignUpdate['successResponse'] }) => {
      dispatch(setCampaign(res.data.campaign));
      message.success('Campaign successfully updated');
    })
    .catch(() => message.error('Update error'));
};

export const createIvite = (
  campaignId: CampaignPublicType['_id'],
  invite: ApiSendInvites['data']
) =>
  request(
    createAxiosConfig<ApiSendInvites>({
      url: ['/campaign', '/', campaignId, '/invite'],
      data: invite,
      method: HTTP_METHODS.POST,
    })
  );

export const createDonor = (data: ApiCreateDonor['data']) =>
  request(
    createAxiosConfig<ApiCreateDonor>({
      url: ['/donors'],
      data,
      method: HTTP_METHODS.POST,
    })
  )
    .then((response: { data: ApiCreateDonor['successResponse'] }) => {
      setBearerToken(response.data.token);
      return response.data;
    })
    .catch((err) => {
      if (
        err.response.data.user &&
        err.response.data.user[0] === 'User with this email already exists'
      ) {
        message.error('User with this email already exists');
      }
      if (
        err.response.data.email &&
        err.response.data.email[0] === 'email must be a valid email'
      ) {
        message.error('Email must be a valid email');
      }
      // debugger;
      if (
        err.response.data.errors.payment &&
        err.response.data.errors.payment[0]
      ) {
        message.error(err.response.data.errors.payment[0]);
      }
    });

export const JoinDonorCampaign = (
  campaignId: CampaignPublicType['_id'],
  inviteCode: CampaignPublicType['inviteCode'],
  donationAmount: CampaignInviteAcceptType['donationAmount'],
  students: CampaignInviteAcceptType['students'],
  choice: CampaignInviteAcceptType['choice'],
  studentNumber: CampaignInviteAcceptType['studentNumber'],
  campaign: any
) =>
  request(
    createAxiosConfig<ApiJoinCampaign>({
      url: ['/donors', '/join/', campaignId],
      method: HTTP_METHODS.POST,
      data: {
        inviteCode,
        donationAmount,
        students,
        choice,
        studentNumber,
        paymentOption: campaign.activePaymentOption,
      },
    })
  )
    .then(
      (response: { data: ApiJoinCampaign['successResponse'] }) => response.data
    )
    .catch((err) => {
      if (err.response.data.errors && err.response.data.errors.campaign) {
        message.error(err.response.data.errors.campaign[0]);
      }
    });

export const selectCampaignList = (state: RootState) =>
  state.campaignState.list;
export const selectCurrentCampaign = (state: RootState) =>
  state.campaignState.currentCampaign;
export const selectPayOpt = (state: RootState) => state.campaignState.payOpt;
export const selectCurrentCampaignCollections = (state: RootState) =>
  state.campaignState.currentCampaignCollections;
export const selectAdminCurrentDonor = (state: RootState) =>
  state.campaignState.adminCurrentDonor;
export const selectCampaignInvite = (state: RootState) =>
  state.campaignState.campaignInviteJoin;
export const selectCampaignDonors = (state: RootState) =>
  state.campaignState.donorList;
export const selectManageDonors = (state: RootState) =>
  state.campaignState.manageList;
export const selectDonorCampaignsList = (state: RootState) =>
  state.campaignState.donorCampaignsList;
export const selectDonorCollections = (state: RootState) =>
  state.campaignState.donorCollections;
export const selectAdminDonorsList = (state: RootState) =>
  state.campaignState.adminDonorsList;
export const selectAdminCampaignsList = (state: RootState) =>
  state.campaignState.adminCampaignsList;
