import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadSchoolFundraisingData, selectSchoolFundraisingData } from '../../../../feature/schoolsState';
import { getUserProfile, getIsUserAuth } from '../../../../feature/userState';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateString } from '../../../../utils/formats';
import SchoolLayoutWithTabs from '../../layout/SchoolLayoutWithTabs';
import CampaignsList from './CampaignsList';
import {ManageTableModel} from "../../types";
import ManageDonorsTable from "../../../donor/dashboard/CollectionsManagement/ManageDonorsTable";

export default function CampaignsListPage() {
  const [selectedPeriod, setSelectedPeriod] = useState<[string, string]>(['', '']);

  const dispatch = useDispatch();
  const { push } = useHistory();

  // const campaigns = useSelector(selectCampaignList);
  const campaignsList = useSelector(selectSchoolFundraisingData);
  const profile = useSelector(getUserProfile);
  const isAuth = useSelector(getIsUserAuth);

  // useEffect(() => {
  //   if (isAuth && !campaigns.length) {
  //     dispatch(loadCampaignList());
  //   }
  // }, [isAuth, dispatch, campaigns]);

  useEffect(() => {
    if (isAuth) {
      const startDate = selectedPeriod[0] ? formatDateString(selectedPeriod[0]) : '';
      const endDate = selectedPeriod[1] ? formatDateString(selectedPeriod[1], true) : '';

      dispatch(loadSchoolFundraisingData(startDate, endDate));
    }
  }, [isAuth, dispatch, selectedPeriod]);

  const onCreateCampaignClickHandler = () => push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_CREATE.getPath());
  const onCampaignClickHandler = (campaignId: string) => push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_INFO.getPath({ campaignId }));

  return (
    <SchoolLayoutWithTabs>
      <CampaignsList campaignsList={campaignsList} isProfileActive={profile?.user.active || false} onCreateCampaignClickHandler={onCreateCampaignClickHandler} onChangeTimePeriod={setSelectedPeriod} onCampaignClickHandler={onCampaignClickHandler} />
    </SchoolLayoutWithTabs>
  );
}
