import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { Row, Switch, Table, Col, Dropdown, Menu, Space, Modal } from 'antd';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import { renderFormConfig } from '../../../../utils/form';
import { CampaignFormFields, CollectionFormFileds } from '../../types';
import {
  campaignSchema,
  collectionCreateSchema,
} from '../../../../project-types/campaign/schemas';
import { DonationAmout, SingleDonationAmountHack } from './DonationAmout';
import EmailRecipients from './EmailRecipients';
import SwitchCheck from '../../../../ui/forms/Switch';
import DatePicker from '../../../../ui/forms/DatePicker';
import InputWrap from '../../../../ui/forms/InputWrap';
import {
  CollectionForm,
  COLLECTION_PERRIODS,
} from '../../../../project-types/campaign/types';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';

import NotifyIcon from '../../../../img/icons/VerificationIcon.svg';
import DataTable from '../../../../ui/elements/Table';
import AnswerRadios from './AnswerRadios';

interface CreateCampaignFormTypes {
  onSubmit: (schoolFields: CampaignFormFields) => void;
}

interface DataType {
  key: string;
  collectionSchemeName: string;
  collectionFrequency: any;
  recurringDonationAmounts: number | null;
  initialDonationAmount: number | null;
  collectionPeriod: string | null;
  numberOfCollections: number | null;
  differentInitalDonation?: boolean;
  giftAid?: boolean;
  collectionType?: string;
  donationAmount: string;
}

const data = [
  // {
  //   key: '1',
  //   collectionSchemeName: 'Collection Scheme Name 1',
  //   collectionFrequency: 'quarterly',
  //   recurringDonationAmounts: 12,
  //   initialDonationAmount: 'The same as monthly payments',
  //   collectionPeriod: 'Fixed number of collections',
  //   numberOfCollections: 10,
  // },
  // {
  //   key: '2',
  //   collectionSchemeName: 'Collection Scheme Name 2',
  //   collectionFrequency: 'montlhy',
  //   recurringDonationAmounts: 12,
  //   initialDonationAmount: 'The same as monthly payments',
  //   collectionPeriod: 'Fixed number of collections',
  //   numberOfCollections: 10,
  // },
];

const columns = [
  {
    title: 'Collection Scheme Name',
    dataIndex: 'collectionSchemeName',
    key: 'collectionSchemeName',
  },
  {
    title: 'Collection Frequency',
    dataIndex: 'collectionFrequency',
    key: 'collectionFrequency',
  },
  {
    title: 'Recurring collection amounts',
    dataIndex: 'recurringDonationAmounts',
    key: 'recurringDonationAmounts',
    render: (record: number) => {
      return <span>&#163; {record}</span>;
    },
  },
  {
    title: 'Initial collection amount',
    dataIndex: 'initialDonationAmount',
    key: 'initialDonationAmount',
    render: (record: number) => {
      return <span>{record === 0 ? 'N/A' : '£ ' + record}</span>;
    },
  },
  {
    title: 'Collection Period',
    dataIndex: 'collectionPeriod',
    key: 'collectionPeriod',
  },
  {
    title: 'Number of collections',
    dataIndex: 'numberOfCollections',
    key: 'numberOfCollections',
    render: (record: number) => {
      return <span>{record === 0 ? '∞' : record}</span>;
    },
  },
];

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;
  margin-top: 10px;
`;

const InputShortStyled = styled(Input)`
  width: 100%;
  max-width: 312px;
  height: 47px !important;
`;

const InputTextarea = styled(TextArea)`
  width: 100%;
  max-width: 421px !important;
  height: 165px;
`;

const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  max-width: 312px;
  border-radius: 4px;
  padding: 14px 14px;
`;

export default function CreateCampaignForm({
  onSubmit,
}: CreateCampaignFormTypes) {
  const [reattempt, setReattempt] = useState(false);
  const [parentInfo, setParentInfo] = useState(false);
  const [continiousCollection, setContiniousCollection] = useState<boolean>(
    false
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState<Array<DataType>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [
    collectionEditCandidate,
    setCollectionEditCandidate,
  ] = useState<DataType | null>(null);
  const [inputType, setInputType] = useState('');

  const formik = useFormik<CampaignFormFields>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      // initialDonation: 0,
      // otherAmounts: [],
      // donationAmounts: [],
      allowCustomAmounts: false,
      isAuthorizationRequired: false,
      differentInitalDonation: false,
      collectionPeriod: COLLECTION_PERRIODS.monthly,
      // numberOfCollections: 1,
      startDate: '',
      isStudentsRefRequired: false,
      collectionAttempts: 0,
      notificationsEmails: [],
      choices: [],
      collectionType: 'payment',
      giftAid: false,
      question: '',
      contributionURL: '',

      // collections: dataSource,
      // campaignName
    },
    validationSchema: campaignSchema,
    onSubmit: (values) => {
      // debugger;
      // const { agree, confirmPassword, ...rows } = values;

      // onSubmit(values);

      onSubmit({ ...values, collections: dataSource });
      // console.log('SSSSSS', { ...values, collections: dataSource });
    },
  });

  const collectionFormik = useFormik<CollectionFormFileds>({
    enableReinitialize: true,
    initialValues: {
      collectionSchemeName: collectionEditCandidate?.collectionSchemeName || '',
      collectionFrequency:
        collectionEditCandidate?.collectionFrequency || 'monthly',
      initialDonationAmount:
        collectionEditCandidate?.initialDonationAmount || 0,
      recurringDonationAmounts:
        collectionEditCandidate?.recurringDonationAmounts || 0,
      collectionPeriod:
        collectionEditCandidate?.collectionPeriod || 'Continuous collections',
      differentInitalDonation:
        collectionEditCandidate?.differentInitalDonation || false,
      numberOfCollections: collectionEditCandidate?.numberOfCollections || 0,
      initialDonation: '',
    },
    // validationSchema: collectionCreateSchema,

    onSubmit: (values, { resetForm }) => {
      // console.log('va', values);

      if (values.collectionFrequency === COLLECTION_PERRIODS.oneoff) {
        const collectionCandidate: DataType = {
          key: String(Date.now()),
          collectionSchemeName: values.collectionSchemeName,
          collectionFrequency: values.collectionFrequency as any,
          recurringDonationAmounts: values.recurringDonationAmounts,
          collectionPeriod: 'oneoff',
          numberOfCollections: 1,
          initialDonationAmount: 0,
          donationAmount: values.differentInitalDonation
            ? 'Initial donation amount is different'
            : `The same as ${values.collectionFrequency} payments`,
        };

        if (collectionEditCandidate) {
          setDataSource((prev) =>
            prev.map((value) => {
              if (value.key === collectionEditCandidate.key) {
                return {
                  ...value,
                  ...collectionCandidate,
                };
              }

              return value;
            })
          );

          return resetForm();
        }

        setDataSource((prev) => prev.concat(collectionCandidate));

        resetForm();

        return;
      }

      const collectionCandidate: DataType = {
        key: String(Date.now()),
        collectionSchemeName: values.collectionSchemeName,
        collectionFrequency: values.collectionFrequency,
        recurringDonationAmounts: values.recurringDonationAmounts,
        collectionPeriod: values.collectionPeriod,
        numberOfCollections: values.numberOfCollections,
        donationAmount: values.differentInitalDonation
          ? 'Initial donation amount is different'
          : `The same as ${values.collectionFrequency} payments`,
        differentInitalDonation: values.differentInitalDonation,
        initialDonationAmount: values.initialDonationAmount,
      };

      if (collectionEditCandidate) {
        setDataSource((prev) =>
          prev.map((value) => {
            if (value.key === collectionEditCandidate.key) {
              return {
                ...value,
                ...collectionCandidate,
              };
            }

            return value;
          })
        );

        return resetForm();
      }

      setDataSource((prev) => prev.concat(collectionCandidate));

      resetForm();

      return;
    },
  });

  const onReattemptChange = (value: boolean) => {
    if (value) {
      formik.setFieldValue('collectionAttempts', 2);
    } else {
      formik.setFieldValue('collectionAttempts', undefined);
    }
    setReattempt(value);
  };
  const onParentInfoChange = (value: boolean) => {
    // if (value) {
    //   formik.setFieldValue('collectionAttempts', 2);
    // } else {
    //   formik.setFieldValue('collectionAttempts', undefined);
    // }
    setParentInfo(value);
  };

  const showModal = () => {
    setCollectionEditCandidate(null);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    collectionFormik.handleSubmit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    collectionFormik.resetForm();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const actionOnDeleteCollection = () => {
    setDataSource((prev) =>
      prev.filter((value) => !selectedRowKeys.includes(value.key))
    );
  };

  const actionOnEditCollection = () => {
    const editCandidate = dataSource.find(
      (value) => value.key === selectedRowKeys[0]
    );

    if (editCandidate) {
      setCollectionEditCandidate(editCandidate);
      setIsModalVisible(true);
    }
  };

  // console.log('edit', collectionEditCandidate);

  const menu = (
    <Menu>
      <Menu.Item onClick={actionOnDeleteCollection}>Delete</Menu.Item>
      <Menu.Item onClick={actionOnEditCollection}>Edit</Menu.Item>
    </Menu>
  );

  // const menu = (
  //     <Menu
  //         items={[
  //           {
  //             label: 'Delete',
  //             key: '0',
  //             onClick: actionOnDeleteCollection,
  //           },
  //           {
  //             label: 'Edit',
  //             key: '1',
  //             onClick: actionOnEditCollection,
  //           },
  //         ]}
  //     />
  // );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <FormStyled>
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>1</StepNumber>
          <Header>Campaign Details</Header>
        </HeaderBlock>
        {renderFormConfig(
          [
            {
              title: 'Enter Campaign Name',
              name: 'name',
              component: InputStyled,
              isRequired: true,
            },
            {
              title: 'Campaign Description',
              name: 'description',
              component: InputTextarea,
              isRequired: true,
            },
            {
              title: 'Campaign Start Date',
              name: 'startDate',
              component: DatePickerStyled,
              isRequired: true,
            },
            {
              title:
                'Is the contributor required to enter a student reference ID?',
              name: 'isStudentsRefRequired',
              isRequired: true,
              component: SwitchCheck,
            },
            {
              title: 'Authorisation is required?',
              name: 'isAuthorizationRequired',
              isRequired: true,
              component: SwitchCheck,
            },
            // {
            //   title: 'Authorisation is required?',
            //   name: 'isAuthorizationRequired',
            //   isRequired: true,
            //   component: SwitchCheck,
            // },
          ],
          formik
        )}
        <InputWrap title="Collection Type" isRequired>
          <RadioGroup
            value={formik.values.collectionType}
            onChange={(e) =>
              formik.setFieldValue('collectionType', e.target.value)
            }
          >
            <RadioItem value="payment">Payment</RadioItem>
            <RadioItem value="donation">Donation</RadioItem>
          </RadioGroup>
        </InputWrap>
        {formik.values.collectionType === 'donation' && (
          <InputWrap title="Gift Aid Eligible?*" isRequired>
            <Switch
              size="small"
              checked={formik.values.giftAid}
              onChange={(e) =>
                formik.setFieldValue('giftAid', !formik.values.giftAid)
              }
            />
          </InputWrap>
        )}
      </InputsGroup>
      <InputsGroup>
        <Divider />
        <HeaderBlock>
          <StepNumber>2</StepNumber>
          <Header>Collection Scheme Options</Header>
        </HeaderBlock>
        <Row>
          <Col span={12} style={{ marginBottom: 20 }}>
            <ButtonDark
              type="button"
              style={{ width: 190, padding: 5, fontSize: 14 }}
              onClick={showModal}
            >
              + Add Collection Option
            </ButtonDark>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>Actions</Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />

        {/* <Divider /> */}
      </InputsGroup>

      <Divider />
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>3</StepNumber>
          <Header>Collection Rules</Header>
        </HeaderBlock>
        <InputWrap
          style={{
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
          }}
          title="Re-attempt collection after an unsuccessful collection"
        >
          <Switch
            size="small"
            checked={reattempt}
            onChange={onReattemptChange}
          />
        </InputWrap>
        <Message>
          <Icon alt="" src={NotifyIcon} />
          <MessageText>
            Collections will be re-attempted on the next available collection
            date, typically this is two weeks after the collection attempt.
          </MessageText>
        </Message>
        <Divider />
      </InputsGroup>
      {reattempt && (
        <InputsGroup>
          <RadioGroupWithLabel>
            <Label>Select from the following rules</Label>
            <RadioGroup
              onChange={(e) =>
                formik.setFieldValue('collectionAttempts', e.target.value)
              }
              value={formik.values.collectionAttempts}
            >
              <VerticalRadio>
                <RadioItem value={1}>
                  After 1 unsuccessful collection attempts freeze collections
                </RadioItem>
                <RadioItem value={2}>
                  After 2 unsuccessful collection attempts freeze collections
                  (Default)
                </RadioItem>
                <RadioItem value={3}>
                  After 3 unsuccessful collection attempts freeze collections
                </RadioItem>
              </VerticalRadio>
            </RadioGroup>
          </RadioGroupWithLabel>
          <Divider />
        </InputsGroup>
      )}
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>4</StepNumber>
          <Header>Collection Notifications</Header>
        </HeaderBlock>
        <LonelyMessageText>
          After each collection date for a campaign, a email report will be sent
          to your accounts email address. You can enter additional email
          addresses below to be additional recipients of the report.
        </LonelyMessageText>
        <EmailRecipients
          name="notificationsEmails"
          setFieldValue={formik.setFieldValue}
        />
        <Divider />
      </InputsGroup>

      <InputsGroup>
        <HeaderBlock>
          <StepNumber>5</StepNumber>
          <Header>School Contribution Agreement</Header>
        </HeaderBlock>
        <InputStyled
          value={formik.values.contributionURL}
          onChange={(e) =>
            formik.setFieldValue('contributionURL', e.target.value)
          }
          placeholder="Choose URL"
        />
        {/* {renderFormConfig(
          [
            {
              title: '',
              name: 'schoolContributionAgreement',
              component: InputStyled,
            },
          ],
          formik
        )} */}

        <Divider />
      </InputsGroup>
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>6</StepNumber>
          <Header>Ask contributor for additional information?</Header>
        </HeaderBlock>
        <InputWrap
          style={{
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
          }}
          title="Yes"
        >
          <Switch
            size="small"
            checked={parentInfo}
            onChange={onParentInfoChange}
          />
        </InputWrap>
        <Divider />
        {parentInfo && (
          <RadioGroup>
            <Header>Choose input type</Header>
            <RadioItem
              value={'text'}
              onChange={(e) => setInputType(e.target.value)}
            >
              Text input
            </RadioItem>
            <RadioItem
              value={'multiple'}
              onChange={(e) => setInputType(e.target.value)}
            >
              Multiple choice
            </RadioItem>
          </RadioGroup>
        )}
      </InputsGroup>
      {inputType == 'text' && (
        <InputsGroup>
          <InputStyled
            value={formik.values.question}
            onChange={(e) => formik.setFieldValue('question', e.target.value)}
            placeholder="Enter question:"
          />
          <Divider />
        </InputsGroup>
      )}
      {inputType == 'multiple' && (
        <div style={{ marginTop: '20px' }}>
          <InputsGroup>
            <InputStyled
              value={formik.values.question}
              onChange={(e) => formik.setFieldValue('question', e.target.value)}
              placeholder="Enter question:"
            />
            <Divider />
          </InputsGroup>
          <AnswerRadios name="choices" setFieldValue={formik.setFieldValue} />
        </div>
      )}
      {/* <button type="submit">submit form</button> */}
      <ButtonsGroup>
        <SubmitStyled
          onClick={(event) => {
            event.preventDefault();
            formik.handleSubmit();
          }}
        >
          Create Campaign
        </SubmitStyled>
      </ButtonsGroup>

      <Modal
        title="New Collection Scheme"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <InputWrap title="Collections Scheme Name" isRequired>
          <InputStyled
            placeholder="Enter collection name"
            value={collectionFormik.values.collectionSchemeName}
            onChange={(e) =>
              collectionFormik.setFieldValue(
                'collectionSchemeName',
                e.target.value
              )
            }
          />
        </InputWrap>

        <InputWrap title="Collection Frequency" isRequired>
          <RadioGroup
            value={collectionFormik.values.collectionFrequency}
            onChange={(e) =>
              collectionFormik.setFieldValue(
                'collectionFrequency',
                e.target.value
              )
            }
          >
            <RadioItem value="monthly">Monthly</RadioItem>
            <RadioItem value="quarterly">Quarterly</RadioItem>
            <RadioItem value="annualy">Annual</RadioItem>
            <RadioItem value="oneoff">One-off</RadioItem>
          </RadioGroup>
        </InputWrap>
        {collectionFormik.values.collectionFrequency === 'oneoff' ? (
          <>
            {renderFormConfig(
              [
                {
                  title: 'Recurring collection amount',
                  name: 'recurringDonationAmounts',
                  isRequired: true,
                  component: DonationAmout,
                  props: {},
                },
              ],
              collectionFormik
            )}
          </>
        ) : (
          <>
            <InputsGroup>
              <InputWrap isRequired title="Initial collection amount">
                <RadioGroup
                  value={collectionFormik.values.differentInitalDonation}
                  onChange={(e) =>
                    collectionFormik.setFieldValue(
                      'differentInitalDonation',
                      e.target.value
                    )
                  }
                >
                  <RadioItem value={false}>
                    The same as{' '}
                    {collectionFormik.values.collectionFrequency !==
                    COLLECTION_PERRIODS.annualy
                      ? collectionFormik.values.collectionFrequency
                      : 'annual'}{' '}
                    payments
                  </RadioItem>
                  <RadioItem value>
                    Initial collection amount is different
                  </RadioItem>
                </RadioGroup>
              </InputWrap>
              {collectionFormik.values.differentInitalDonation &&
                renderFormConfig(
                  [
                    {
                      title: 'Set Initial collection amount',
                      name: 'initialDonationAmount',
                      component: DonationAmout,
                      isRequired: true,
                      props: {
                        type: 'number',
                      },
                    },
                  ],
                  collectionFormik
                )}

              {renderFormConfig(
                [
                  {
                    title: 'Recurring collection amount',
                    name: 'recurringDonationAmounts',
                    isRequired: true,
                    component: DonationAmout,
                    props: {},
                  },
                ],
                collectionFormik
              )}

              {/* <InputStyled
                type="number"
                value={collectionFormik.values.recurringDonationAmounts}
                onChange={(e: any) =>
                  collectionFormik.setFieldValue(
                    'recurringDonationAmounts',
                    e.target.value
                  )
                }
              /> */}
              {/* {renderFormConfig(
                [
                  {
                    title: 'Recurring donation amounts',
                    name: 'recurringDonationAmounts',
                    isRequired: true,
                    component: SingleDonationAmountHack,
                  },
                ],
                collectionFormik
              )} */}
              <Divider />
            </InputsGroup>
            <InputsGroup>
              <RadioGroupWithLabel>
                <Label>Collection Period</Label>
                <RadioGroup
                  value={collectionFormik.values.collectionPeriod}
                  onChange={(e) => {
                    // setContiniousCollection(e.target.value);
                    // if (!e.target.value) {
                    //   collectionFormik.setFieldValue('numberOfCollections', 1);
                    // } else {
                    //   collectionFormik.setFieldValue(
                    //     'numberOfCollections',
                    //     undefined
                    //   );
                    // }
                    collectionFormik.setFieldValue(
                      'collectionPeriod',
                      e.target.value
                    );
                  }}
                >
                  <RadioItem value="Continuous collections">
                    Continuous collections
                  </RadioItem>
                  <RadioItem value="Fixed number of collections">
                    Fixed number of collections
                  </RadioItem>
                </RadioGroup>
              </RadioGroupWithLabel>
            </InputsGroup>
            {collectionFormik.values.collectionPeriod ===
              'Fixed number of collections' &&
              renderFormConfig(
                [
                  {
                    title: 'The number of collections to be made',
                    name: 'numberOfCollections',
                    component: InputShortStyled,
                    props: {
                      type: 'number',
                      min: 1,
                    },
                    isRequired: true,
                  },
                ],
                collectionFormik
              )}
          </>
        )}

        <Row>
          <Col span={12}>
            <ButtonLight
              onClick={handleCancel}
              style={{ width: 210, padding: 8, fontSize: 14 }}
            >
              Cancel
            </ButtonLight>
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <ButtonDark
              onClick={handleOk}
              style={{ width: 210, padding: 8, fontSize: 14 }}
              disabled={
                collectionFormik.values.collectionSchemeName ? false : true
              }
            >
              Save
            </ButtonDark>
          </Col>
        </Row>
      </Modal>
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
  margin-top: 36px;
`;

const ButtonsGroup = styled.div`
  margin: 45px 0;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 13px 50px;
`;

const InputsGroup = styled.div`
  /* margin-bottom: 75px; */
`;

const HeaderBlock = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 29px;
  align-items: center;
`;

const Header = styled.h2`
  font-size: 20px;
  margin: 0;
  font-weight: bold;
`;

const StepNumber = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  color: #388de5;
  background-color: #e8f4ff;
  padding: 4px 7px;
  font-weight: bold;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #d5dce5;
  margin: 29px 0;
`;

const Label = styled.span`
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 4px;
  &::after {
    content: '*';
    padding-left: 2px;
    color: red;
  }
`;

const RadioGroupWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Message = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin: 0;
`;

const MessageText = styled.p`
  font-size: 12px;
  margin: 0;
  width: 100%;
  max-width: 421px;
`;

const LonelyMessageText = styled(MessageText)`
  margin-bottom: 30px;
`;

const VerticalRadio = styled.div`
  display: flex;
  flex-direction: column;
`;
