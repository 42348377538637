import { FC, useState } from 'react';
import { Radio, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import {showPriceDigits} from "../../../../../utils/formats";

const SelectPaymentOption: FC<{
  collections: Array<any>;
  onChange: (id: any) => void;
  activeId: string;
}> = ({ collections = [], onChange, activeId }) => {
  // const [value, setValue] = useState(1);

  // const onChange = (e: RadioChangeEvent) => {
  //   // console.log('radio checked', e.target.value);
  //   setValue(e.target.value);
  // };

  const payments = collections.map((collection) => ({
    value: collection._id,
    string: `${
      collection.numberOfCollections === 0
        ? 'Continuous'
        : collection.collectionFrequency === 'oneoff' ? 'One-off': collection.numberOfCollections
      
    } ${collection.collectionFrequency === 'oneoff' ? 'collection' : 'collections'}   of \u00A3${
        showPriceDigits(collection.recurringDonationAmounts)
    }${collection.collectionFrequency === 'oneoff' ? '' : '/' + collection.collectionFrequency} via Direct Debit`,
  }));
  // £ ${showPriceDigits(donor.donationAmount)}
  // console.log('values', payments);

  return (
    <Radio.Group onChange={onChange} value={activeId}>
      <Space direction="vertical">
        {payments.map((payment) => (
          <RadioContainer
            key={payment.value}
            active={activeId === payment.value}
          >
            <Radio value={payment.value}>{payment.string}</Radio>
          </RadioContainer>
        ))}
      </Space>
    </Radio.Group>
  );
};

const RadioContainer = styled.div<{ active: boolean }>`
  border: 1.3px solid ${(props) => (props.active ? '#2682DE' : '#e0e0e0')};
  border-radius: 5px;

  background: #ffffff;

  padding: 7px;

  width: 450px;
`;

export default SelectPaymentOption;
