import { FC } from 'react';
import styled from 'styled-components';
import {showPriceDigits} from "../../../../../utils/formats";

interface IPaymentTypeProps {
  schemeName: string;
  initialCollectionAmount: number;
  recurringCollectionAmount: number;
  collectionFrequency: string;
  numberOfCollections: number;
}

const PaymentType: FC<IPaymentTypeProps> = ({
  schemeName,
  initialCollectionAmount,
  recurringCollectionAmount,
  collectionFrequency,
  numberOfCollections,
}) => {
  return (
    <>
      <DetailsMainTitle>{schemeName}</DetailsMainTitle>
      <Details>
        <DetailsContainer>
          <DetailsKeyTitle>Initial Collection Amount</DetailsKeyTitle>

          <DetailsValueTitle>{initialCollectionAmount === 0 ? "N/A" : '£ ' + initialCollectionAmount}</DetailsValueTitle>
        </DetailsContainer>
        <DetailsContainer>
          <DetailsKeyTitle>Recurring Collection Amount</DetailsKeyTitle>
          <DetailsValueTitle>
            &#163;{showPriceDigits(recurringCollectionAmount)}
            {/*{recurringCollectionAmount}*/}
          </DetailsValueTitle>
        </DetailsContainer>

        <DetailsContainer>
          <DetailsKeyTitle>Collection Frequency</DetailsKeyTitle>
          <DetailsValueTitle>{collectionFrequency}</DetailsValueTitle>
        </DetailsContainer>

        <DetailsContainer>
          <DetailsKeyTitle>Number Of Collections</DetailsKeyTitle>
          <DetailsValueTitle>
            {numberOfCollections === 0 ? '\u221E' : numberOfCollections}
          </DetailsValueTitle>
        </DetailsContainer>

        <DetailsContainer>
          <DetailsKeyTitle>Payment Method</DetailsKeyTitle>
          <DetailsValueTitle>Direct Debit</DetailsValueTitle>
        </DetailsContainer>
      </Details>
    </>
  );
};

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 28px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailsKeyTitle = styled.span`
  font-weight: 400;
  font-size: 14px;

  color: #102f54;
`;

const DetailsValueTitle = styled.span`
  font-weight: 600;
  font-size: 14px;

  color: #102f54;

  ::first-letter {
    text-transform: capitalize;
  }
`;

const DetailsMainTitle = styled.span`
  font-weight: 600;
  font-size: 16px;

  color: #6b7a87;
`;

export default PaymentType;
