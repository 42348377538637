import styled from 'styled-components/macro';
import Logo from './Logo';

import TwitterIcon from '../../img/icons/social-icons/twitter.svg';
import FacebookIcon from '../../img/icons/social-icons/facebook.svg';
import LinkedinIcon from '../../img/icons/social-icons/linkedin.svg';

export default function Footer() {
  return (
    <Container>
      <LinksContainer>
        <LinksBlock>
          <LinksTitle>Help</LinksTitle>
          <Link>Support centre</Link>
          <Link>Contact Us</Link>
          <Link>FAQs</Link>
        </LinksBlock>
        <RightLinksBlock>
          <LinksBlock>
            <LinksTitle>Services</LinksTitle>
            <Link>Collection Schemes</Link>
            <Link>Device Trade-in</Link>
          </LinksBlock>
          <LinksBlock>
            <LinksTitle>Legal</LinksTitle>
            <Link>Website Terms</Link>
            <Link>Privacy Policy</Link>
            <Link>Service Agreement</Link>
          </LinksBlock>
        </RightLinksBlock>
      </LinksContainer>
      <BottomContainer>
        <Logo />
        <StyledText>© 2021 Devices For Education, Inc. All Rights Reserved. Terms, Privacy and Cookies </StyledText>
        <SocialLinksBlock>
          <SocialLink>
            <img alt="" src={FacebookIcon} />
          </SocialLink>
          <SocialLink>
            <img alt="" src={LinkedinIcon} />
          </SocialLink>
          <SocialLink>
            <img alt="" src={TwitterIcon} />
          </SocialLink>
        </SocialLinksBlock>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.footer`
  padding: 30px 150px;
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  margin-top: auto;
`;

const LinksContainer = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;

const RightLinksBlock = styled.div`
  display: flex;
  gap: 100px;
`;

const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LinksTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: #6B7A87;
  margin-bottom: 5px;
`;

const Link = styled.a`
  text-decoration: none;
  color: #909DA8;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  margin-top: 49px;
  padding: 20px 10px 30px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #DEE7F0;
`;

const StyledText = styled.span`
  color: #909DA8;
  font-size: 14px;
`;

const SocialLinksBlock = styled.div`
  display: flex;
  gap: 12.5px;
`;

const SocialLink = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #FAFAFA;
  width: 28px;
  height: 28px;
`;
